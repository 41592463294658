<!-- 
author:张洪志
description:登录
 -->
<template>
  <section class="login-sec">
    <div v-if="!isShare" class="nav">
      <div class="vw">
        <router-link to="/"><img src="../../static/img/logo-0.png"></router-link>
        <span class="txt">登录</span>
      </div>
    </div>
    <div class="pub-login" v-if="!isShare">
      <div class="banner">
        <div class="banner-vw">
          <div class="vw flex-between">
            <div style="color: #fff;">            
              <p style="font:52px/1.6em microsoft yahei;">Consulting&lnvestment</p>
              <p style="font:52px/1.6em microsoft yahei;">博采众长·惠泽天下</p>
              <p style="font:25px/1.6em microsoft yahei;  margin-bottom: 30px;">省时省心省力7x24小时实时交易</p>
              <router-link style="border:1px solid #fff; padding: 10px 30px; border-radius: 2em; background: none; color: #fff; font-size: 18px;" class="btn" :to="{name:'FundMarket'}">点击查看 →</router-link>
            </div>
            <login-in />
          </div>
        </div>
      </div>
    </div>
    <div class="share-login" v-else>
      <div :class="['banner',{'banner-bg':showBannerBg}]">
        <div class="banner-in">
          <el-carousel height="550px">
            <el-carousel-item v-for="item in banners" :key="item.id">
              <a :href="item.href" :title="item.title">
                <img :src="item.src" :alt="item.title">
              </a>
            </el-carousel-item>
          </el-carousel>        
        </div>
        <div class="banner-vw" >
          <div class="vw flex-between" style="padding-top: 45px;">
            <div>
            </div>
            <login-in class="login-box" :isShare="true" />
          </div>
        </div>
      </div>
      <div class="s1 vw">
        <div class="title">
          <img src="../../static/img/other/s1_r2_c2.jpg">
        </div>
        <div class="content flex-between">
          <img src="../../static/img/other/s1_r5_c1.jpg">
          <img src="../../static/img/other/s1_r5_c4.jpg">
          <img src="../../static/img/other/s1_r5_c6.jpg">
          <img src="../../static/img/other/s1_r5_c10.jpg">
        </div>
      </div>
      <div class="s2">
        <div class="title">
          — 众惠基金 让投资触手可及 —
        </div>
        <div class="vw flex-evenly">
          <img src="../../static/img/other/s2_r1_c1.jpg">
          <img src="../../static/img/other/s2_r1_c3.jpg">
          <img src="../../static/img/other/s2_r1_c5.jpg">
        </div>
      </div>
      <div class="s3 vw">
        <div class="title">安全保障</div>
        <div class="flex-between">
          <img src="../../static/img/other/s3_r1_c1.jpg" @click="toggle_1(true)" class="btn">
          <img src="../../static/img/other/s3_r1_c3.jpg">
          <img src="../../static/img/other/s3_r1_c5.jpg">
          <img src="../../static/img/other/s3_r1_c7.jpg" @click="toggle_2(true)" class="btn">
        </div>
        <div class="txt">众惠基金是贵台实业有限公司的全资子公司，拥有证监会核发的独立基金销售牌照，中国证监会为监督机构，民生银行为基金销售监管银行，并通过中登公司登记资产。</div>
      </div>
      <div class="s4">
        <div class="box vw">
          <img src="../../static/img/other/s4.jpg">
        </div>
      </div>
      <div class="s5">
        <div class="vw">
          <img src="../../static/img/other/s5.jpg">
        </div>
      </div>
      <div class="mask btn" v-show="visible_1" @click="toggle_1(false)" title="点击还原">
        <img src="../../static/img/cert/1.jpg">
      </div>    
      <div class="mask btn" v-show="visible_2" @click="toggle_2(false)" title="点击还原">
        <img src="../../static/img/cert/4.jpg">
      </div>
    </div>
  </section>
</template>

<script>
  import LoginIn from './components/Login.vue'
  import {getDicts} from '@/api/dict.js'
  import {getImgs} from '@/api/other/index.js'
  import {channelCount} from '@/api/user.js'
  export default {
    components:{
      LoginIn
    },
    name: 'Login',
    data() {
      return {
        visible_1: false,
        visible_2: false,
        banners:[],
        showBannerBg: false
      }
    },
    computed: {
      isShare() {
        return this.$route.query.isShare == '1'
      }
    },
    created() {
      getDicts('sys_img_user_site').then(result => {
        let imgUseSite = (result.data.find(m => m.dictLabel === 'web端落地页轮播')||{}).dictValue
        if(imgUseSite) {
          getImgs('1',imgUseSite).then(imgResult => {
            this.banners = Array.from(imgResult.data,m => {
              return {
                title:m.imgName,
                href:m.imgLinkedUrl,
                src:m.imgUrl,
                id:m.imgId
              }
            })
            this.showBannerBg = this.banners.length === 0
          }).catch(() => {
            this.showBannerBg = true
          })
        } else {
          this.showBannerBg = true
        }
      })
      if(this.isShare && this.$route.query.channel == '4') {
        channelCount(this.$route.query.popChannelId,1).catch(() => {})
      }
    },
    methods: {
      toggle_1(show) {
        this.visible_1 = show
      },
      toggle_2(show) {
        this.visible_2 = show
      }
    },
  }
</script>

<style lang="less" scoped>
  .login-sec{position: relative;}
  .nav{padding:20px 0;  background: rgb(207 158 104 / 80%); color:#FFEFD3; margin-bottom: -90px; position: relative; z-index: 2;
    .vw{background: none; display: flex; align-items: center;}
    .txt{ font:28px "microsoft yahei"; margin-left:40px;}
  }
  .banner{height: 550px; position: relative; z-index:1; overflow: hidden;}
  .pub-login .banner{ background: #102131 url(../../static/img/banner/home%20banner.jpg) center no-repeat;}
  .share-login .banner-bg{background: url(../../static/img/reg/banner_bg.jpg) no-repeat center #DD291B;}
  .banner-in { background: none;
    img{display: block;}
  }
  .banner-vw{ position: relative; z-index: 2; left: 0; top:0; width:100%; height:100%; 
    .vw{align-items: center; background: none; height:460px; padding-top: 90px;}
    .login-box{ margin-right: 1em; margin-top: -1100px;}
  }
  .s1{background: url(../../static/img/other/s1_r1_c8.jpg) no-repeat right top; padding:60px 0;
    .vw{margin-top:50px;}
  }
  .s2{background: url(../../static/img/other/redbg.jpg) repeat-x top left #F3F4F6; height:570px;
    .title{ text-align: center; font:bold 24px/4em "microsoft yahei"; color:#fff; letter-spacing: 5px;}
    .vw{ background: none;}
  }
  .s3{text-align: center;
    .title{font:bold 30px/5em "microsoft yahei";}
    .txt{ line-height:120px ; padding-bottom: 30px;}
  }
  .s4{ background: #F13528; padding:28px;
    .box{ text-align: center; background: none; line-height: 1em;
      img{border-radius: 10px;}
    }
  }
  .s5{ background: #F13528; padding:20px 0 50px 0;
    .vw{border-radius: 10px; text-align: center; padding:50px 0;}
  }
  .mask{ position: fixed; min-height: 100%; width:100%; top:0; left:0; background: rgba(255,255,255,0.5); display:flex; justify-content: center; align-items: center;
    &>*{ background: #fff; box-shadow: 0 0 10px #ccc;}
  }
</style>
